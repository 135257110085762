// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    
    

    /*
    render function
    */
    import { render } from 'js/swf3dWorkspaceTableActions';
    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/swf3dWorkspaceTableActions', () => import('js/swf3dWorkspaceTableActions'));
registerDynImportEntry('js/swf3dTcxWorkspace', () => import('js/swf3dTcxWorkspace'));;

    /*
    view model
    */
    const declViewModelJson = {
    "imports": [],
    "i18n": {
        "searchTitle": [
            "swf3dTcxWorkspaceMessages"
        ],
        "partNumberTitle": [
            "swf3dTcxWorkspaceMessages"
        ],
        "searchPartTitle": [
            "swf3dTcxWorkspaceMessages"
        ],
        "itemIdTitle": [
            "swf3dTcxWorkspaceMessages"
        ],
        "revisionTitle": [
            "swf3dTcxWorkspaceMessages"
        ],
        "nameTitle": [
            "swf3dTcxWorkspaceMessages"
        ],
        "itemTypeTitle": [
            "swf3dTcxWorkspaceMessages"
        ],
        "partTypeTitle": [
            "swf3dTcxWorkspaceMessages"
        ],
        "modifyDateTitle": [
            "swf3dTcxWorkspaceMessages"
        ],
        "fileSizeTitle": [
            "swf3dTcxWorkspaceMessages"
        ]
    },
    "data": {
        "loadMore": {
            "type": "STRING",
            "isRequired": "false",
            "isEditable": "false",
            "dbValue": "Load More",
            "dispValue": "Load More"
        },
        "dialogSettings": {
            "widthType": "APOLLO"
        },
        "searchPartUrn": {
            "displayName": "{{i18n.partNumberTitle}}",
            "type": "STRING",
            "isRequired": "true",
            "isEditable": "true",
            "dbValue": "",
            "dispValue": "*"
        },
        "pageSize": 10,
        "percentComplete": 0,
        "defaultGroup": {
            "sdkProp": "DefaultGroup",
            "view": "uicompGroup"
        }
    },
    "props": {
        "type": {
            "type": "string"
        },
        "tenantid": {
            "type": "string"
        },
        "url": {
            "type": "object"
        },
        "isTcx": {
            "type": "boolean"
        }
    },
    "actions": {
        "render": {
            "method": "render",
            "deps": "js/swf3dWorkspaceTableActions"
        },
        "setViewAndRegisterUrn": {
            "actionType": "JSFunction",
            "method": "setViewAndRegisterUrn",
            "inputData": {
                "name": "galaxy.workspace.tableCurrentFolderUrn",
                "value": ""
            },
            "deps": "js/swf3dWorkspaceTableActions"
        },
        "loadData": {
            "actionType": "JSFunction",
            "method": "loadData",
            "inputData": {
                "data": "{{data}}"
            },
            "outputData": {
                "filterResults": "searchResults",
                "totalFound": "totalFound"
            },
            "events": {
                "success": [
                    {
                        "name": "galaxy.workspace.loadDataSuccess"
                    }
                ]
            },
            "deps": "js/swf3dWorkspaceTableActions"
        },
        "swf3dWorkspaceLoadDataSuccess": {
            "actionType": "JSFunction",
            "method": "swf3dWorkspaceLoadDataSuccess",
            "inputData": {
                "data": "{{data}}"
            },
            "deps": "js/swf3dWorkspaceTableActions"
        },
        "workspaceClickAction": {
            "actionType": "JSFunction",
            "method": "selectionChangedAction",
            "inputData": {
                "eventData": "{{parameters.eventData}}",
                "data": "{{data}}"
            },
            "deps": "js/swf3dWorkspaceTableActions"
        },
        "selectionChangedAction": {
            "actionType": "JSFunction",
            "method": "selectionChangedAction",
            "deps": "js/swf3dWorkspaceTableActions",
            "inputData": {
                "value": "{{data.eventData.selectedObjects[0]}}",
                "data": "{{data}}"
            }
        },
        "resetWorkspaceData": {
            "actionType": "JSFunction",
            "method": "resetWorkspaceData",
            "inputData": {
                "data": "{{data}}"
            },
            "deps": "js/swf3dWorkspaceTableActions"
        },
        "loadColumnsAndAddCellRenderer": {
            "actionType": "JSFunction",
            "method": "loadColumnsAndAddCellRenderer",
            "inputData": {
                "columnProvider": "{{data.columnProviders.Swf3dColumnProvider}}",
                "dataProvider": "{{data.dataProviders.Swf3dDataProvider}}"
            },
            "deps": "js/swf3dWorkspaceTableActions"
        },
        "searchPart": {
            "actionType": "JSFunction",
            "method": "searchPart",
            "inputData": {
                "qString": "{{data.searchPartUrn.dbValue}}",
                "dataProvider": "{{dataProviders.tcxPartListDataProvider}}"
            },
            "deps": "js/swf3dTcxWorkspace"
        },
        "loadTcxPartList": {
            "actionType": "JSFunction",
            "method": "getPartList",
            "inputData": {
                "qString": "{{data.searchPartUrn.dbValue}}"
            },
            "outputData": {
                "partList": "partList",
                "totalFound": "partList.length"
            },
            "deps": "js/swf3dTcxWorkspace",
            "events": {}
        },
        "tcxWorkspaceClickAction": {
            "actionType": "JSFunction",
            "method": "selectionChangedAction",
            "inputData": {
                "eventData": "{{parameters.eventData}}",
                "data": "{{data}}"
            },
            "deps": "js/swf3dTcxWorkspace"
        },
        "tcxTableSelectionChangedAction": {
            "actionType": "JSFunction",
            "method": "selectionChangedAction",
            "deps": "js/swf3dTcxWorkspace",
            "inputData": {
                "value": "{{data.eventData.selectedObjects[0]}}",
                "data": "{{data}}"
            }
        }
    },
    "columnProviders": {
        "Swf3dColumnProvider": {
            "frozenColumnIndex": -1,
            "loadColumnAction": "loadColumnsAndAddCellRenderer",
            "sortCriteria": [],
            "columns": [
                {
                    "name": "object",
                    "displayName": "Object",
                    "minWidth": 100,
                    "maxWidth": 400,
                    "width": 200,
                    "enableSorting": false,
                    "enableColumnMenu": false,
                    "enableColumnMoving": false
                },
                {
                    "name": "name",
                    "displayName": "Name",
                    "minWidth": 100,
                    "maxWidth": 400,
                    "width": 200,
                    "enableColumnMenu": true,
                    "enableColumnMoving": false
                },
                {
                    "name": "type",
                    "displayName": "Type",
                    "minWidth": 100,
                    "maxWidth": 400,
                    "width": 200,
                    "enableColumnMenu": true,
                    "enableColumnMoving": false
                },
                {
                    "name": "date",
                    "displayName": "Date",
                    "minWidth": 100,
                    "maxWidth": 400,
                    "width": 200,
                    "enableColumnMenu": true,
                    "enableColumnMoving": false
                },
                {
                    "name": "createBy",
                    "displayName": "Created By",
                    "minWidth": 100,
                    "maxWidth": 400,
                    "width": 200,
                    "enableColumnMenu": true,
                    "enableColumnMoving": false
                }
            ]
        },
        "tcxPartListColumnProvider": {
            "frozenColumnIndex": 1,
            "columns": [
                {
                    "name": "id",
                    "displayName": "",
                    "minWidth": 10,
                    "maxWidth": 50,
                    "width": 20,
                    "enableSorting": false,
                    "enableColumnMenu": false,
                    "enableColumnMoving": false
                },
                {
                    "name": "itemId",
                    "displayName": "{{i18n.itemIdTitle}}",
                    "minWidth": 100,
                    "maxWidth": 400,
                    "width": 200,
                    "pinnedLeft": false,
                    "isTreeNavigation": false,
                    "enableColumnMoving": false,
                    "enableColumnResizing": true,
                    "enableColumnMenu": false,
                    "enableSorting": false
                },
                {
                    "name": "revId",
                    "displayName": "{{i18n.revisionTitle}}",
                    "minWidth": 100,
                    "maxWidth": 200,
                    "width": 100,
                    "pinnedLeft": false,
                    "isTreeNavigation": false,
                    "enableColumnMoving": false,
                    "enableColumnResizing": true,
                    "enableColumnMenu": false,
                    "enableSorting": false
                },
                {
                    "name": "name",
                    "displayName": "{{i18n.nameTitle}}",
                    "minWidth": 100,
                    "maxWidth": 400,
                    "width": 200,
                    "pinnedLeft": false,
                    "isTreeNavigation": false,
                    "enableColumnMoving": false,
                    "enableColumnResizing": true,
                    "enableColumnMenu": false,
                    "enableSorting": false
                },
                {
                    "name": "itemType",
                    "displayName": "{{i18n.itemTypeTitle}}",
                    "minWidth": 100,
                    "maxWidth": 400,
                    "width": 100,
                    "pinnedLeft": false,
                    "isTreeNavigation": false,
                    "enableColumnMoving": false,
                    "enableColumnResizing": true,
                    "enableColumnMenu": false,
                    "enableSorting": false
                },
                {
                    "name": "partType",
                    "displayName": "{{i18n.partTypeTitle}}",
                    "minWidth": 100,
                    "maxWidth": 200,
                    "width": 100,
                    "pinnedLeft": false,
                    "isTreeNavigation": false,
                    "enableColumnMoving": false,
                    "enableColumnResizing": true,
                    "enableColumnMenu": false,
                    "enableSorting": false
                },
                {
                    "name": "pdmLastModDate",
                    "displayName": "{{i18n.modifyDateTitle}}",
                    "minWidth": 100,
                    "maxWidth": 300,
                    "width": 180,
                    "pinnedLeft": false,
                    "isTreeNavigation": false,
                    "enableColumnMoving": false,
                    "enableColumnResizing": true,
                    "enableColumnMenu": false,
                    "enableSorting": false
                },
                {
                    "name": "flpSize",
                    "displayName": "{{i18n.fileSizeTitle}}",
                    "minWidth": 100,
                    "maxWidth": 400,
                    "width": 120,
                    "pinnedLeft": false,
                    "isTreeNavigation": false,
                    "enableColumnMoving": false,
                    "enableColumnResizing": true,
                    "enableColumnMenu": false,
                    "enableSorting": false
                }
            ]
        }
    },
    "dataProviders": {
        "Swf3dDataProvider": {
            "action": "loadData",
            "response": "{{data.filterResults}}",
            "totalFound": "{{data.totalFound}}"
        },
        "tcxPartListDataProvider": {
            "action": "loadTcxPartList",
            "response": "{{data.partList}}",
            "totalFound": "{{data.totalFound}}",
            "selectionModelMode": "single"
        }
    },
    "grids": {
        "swf3dWorkspaceTable": {
            "dataProvider": "Swf3dDataProvider",
            "columnProvider": "Swf3dColumnProvider",
            "addIconColumn": false,
            "gridOptions": {
                "enableGridMenu": false,
                "enablePinning": true,
                "enableSorting": true
            }
        },
        "tcxPartListTable": {
            "dataProvider": "tcxPartListDataProvider",
            "columnProvider": "tcxPartListColumnProvider",
            "addIconColumn": true,
            "gridOptions": {
                "enablePinning": true,
                "enableSorting": false
            }
        }
    },
    "lifecycleHooks": {
        "render": "render",
        "onMount": "setViewAndRegisterUrn"
    },
    "onEvent": [
        {
            "eventId": "galaxy.workspace.loadDataSuccess",
            "action": "swf3dWorkspaceLoadDataSuccess"
        },
        {
            "eventId": "galaxy.workspace.onGridObjectSelected",
            "inputArgs": {
                "eventData": "{{eventData}}"
            },
            "action": "workspaceClickAction"
        },
        {
            "eventId": "Swf3dDataProvider.selectionChangeEvent",
            "cacheEventData": true,
            "action": "selectionChangedAction"
        },
        {
            "eventId": "galaxy.workspace.resetTable",
            "action": "resetWorkspaceData"
        },
        {
            "eventId": "galaxy.workspace.loadData",
            "action": "loadData"
        },
        {
            "eventId": "galaxy.workspace.onGridObjectSelected",
            "inputArgs": {
                "eventData": "{{eventData}}"
            },
            "action": "tcxWorkspaceClickAction"
        },
        {
            "eventId": "tcxPartListDataProvider.selectionChangeEvent",
            "cacheEventData": true,
            "action": "tcxTableSelectionChangedAction"
        }
    ],
    "conditions": {
        "isSetCurrentFolderUrn": {
            "expression": "ctx.galaxy.workspace.tableCurrentFolderUrn === undefined"
        }
    },
    "_viewModelId": "Swf3dWorkspace",
    "_uniqueViewModelId": "Swf3dWorkspace"
};

    


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, render, undefined, ctxImportObj );

    export default Component;