// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanel from 'viewmodel/AwPanelViewModel';
import AwList from 'viewmodel/AwListViewModel';
import AwCheckbox from 'viewmodel/AwCheckboxViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/swf3dWorkspaceFilterActions', () => import('js/swf3dWorkspaceFilterActions'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "data": {},
    "dataProviders": {
        "loadDestinations": {
            "dataProviderType": "Action",
            "action": "renderFilterList",
            "response": "{{data.filterList}}",
            "totalFound": "{{data.count}}",
            "selectionModelMode": "none"
        }
    },
    "actions": {
        "reveal": {
            "actionType": "dataProvider",
            "method": "loadDestinations"
        },
        "renderFilterList": {
            "actionType": "JSFunction",
            "method": "renderFilterList",
            "outputData": {
                "filterList": "filterList",
                "count": "filterList.length"
            },
            "deps": "js/swf3dWorkspaceFilterActions"
        },
        "selectTransferDestination": {
            "actionType": "JSFunction",
            "method": "selectTransferDestination",
            "inputData": {
                "data": "{{data}}"
            },
            "deps": "js/swf3dWorkspaceFilterActions"
        },
        "loadingSuccess": {
            "actionType": "JSFunction",
            "method": "loadingSuccess",
            "inputData": {
                "data": "{{data}}"
            },
            "deps": "js/swf3dWorkspaceFilterActions"
        }
    },
    "onEvent": [
        {
            "eventId": "galaxy.workspace.loadDataSuccess",
            "action": "loadingSuccess"
        }
    ],
    "_viewModelId": "swf3dWorkspaceFilter",
    "_uniqueViewModelId": "swf3dWorkspaceFilter",
    "ctx": {}
};

    /*
    <aw-panel>
    <aw-list dataprovider='data.dataProviders.loadDestinations'>
        <aw-checkbox prop='item' action='selectTransferDestination'></aw-checkbox>
    </aw-list>
</aw-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanel  revealAction={actions.reveal}>
        <AwList dataprovider={dataProviders.loadDestinations} showDropArea={null} dndHandler={props.viewModel.dndHandler}>{({item, index})=>{ return <>            <AwCheckbox {...getField("item", fields, $index, null, null )} action={actions.selectTransferDestination}>
            </AwCheckbox></> } }
        </AwList>
    </AwPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;