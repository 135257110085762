// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    
    

    /*
    render function
    */
    import { render } from 'js/swf3dWorkspaceBreadcrumbService';
    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/swf3dWorkspaceBreadcrumbService', () => import('js/swf3dWorkspaceBreadcrumbService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "imports": [],
    "data": {
        "dynamicCrumbs": [
            {
                "displayName": "My Workspace",
                "type": "Home",
                "urn": "{{ctx.galaxy.workspace.baseUrn}}",
                "selectedCrumb": false,
                "showArrow": false,
                "scopedUid": 1,
                "clicked": false,
                "level": 1
            }
        ]
    },
    "actions": {
        "render": {
            "method": "render",
            "deps": "js/swf3dWorkspaceBreadcrumbService"
        },
        "buildBreadcrumb": {
            "actionType": "JSFunction",
            "method": "buildBreadcrumb",
            "deps": "js/swf3dWorkspaceBreadcrumbService",
            "inputData": {
                "eventData": "{{parameters.eventData}}",
                "originalCrumbs": "{{data.dynamicCrumbs}}"
            },
            "outputData": {
                "dynamicCrumbs": "crumbs"
            }
        },
        "updateBreadcrumb": {
            "actionType": "JSFunction",
            "method": "updateBreadcrumb",
            "deps": "js/swf3dWorkspaceBreadcrumbService",
            "inputData": {
                "eventData": "{{parameters.eventData}}",
                "originalCrumbs": "{{data.dynamicCrumbs}}"
            },
            "outputData": {
                "dynamicCrumbs": "crumbs"
            }
        }
    },
    "lifecycleHooks": {
        "render": "render"
    },
    "onEvent": [
        {
            "eventId": "galaxy.workspace.newBreadcrumb",
            "inputArgs": {
                "eventData": "{{eventData}}"
            },
            "action": "buildBreadcrumb"
        },
        {
            "eventId": "galaxy.workspace.updateBreadcrumb",
            "inputArgs": {
                "eventData": "{{eventData}}"
            },
            "action": "updateBreadcrumb"
        }
    ],
    "_viewModelId": "Swf3dWorkspaceBreadcrumb",
    "_uniqueViewModelId": "Swf3dWorkspaceBreadcrumb",
    "ctx": {
        "galaxy": {
            "type": "object"
        }
    }
};

    


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, render, undefined, ctxImportObj );

    export default Component;